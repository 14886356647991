
































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Agency from "~/models/Agency"
import { enums } from "@/store"
import { isValidPhoneNumber } from "~/utils/validate"
import { brands, agencies, Hit, Brand } from "~/config/algolia"
import { autocompletePlace, geocodePlaceId } from "~/plugins/places"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    OcularDialog,
  },
})
export default class CreateAgency extends Vue {
  @Prop({ default: () => new Agency() }) agency!: Agency
  @Prop({ default: null }) onValidate!: null | ((agency: Agency) => any)
  searchBrands = ""
  isPhoneNumberAvailable = true
  brands: Hit<Brand>[] = []
  placeId = ""
  searchAddress = ""
  predictedPlaces: google.maps.places.AutocompletePrediction[] = []
  loading = false

  open() {
    this.dialog.open = true
    if (this.agency && this.agency.brand_id) {
      this.findBrands(this.agency.brand_id.toString() || "")
    }
  }
  close() {
    this.dialog.open = false
  }
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }
  get categories() {
    return Object.entries(enums("categories"))
  }
  get agencyAddress() {
    return this.agency.street_address
      ? `${this.agency.street_address} ${this.agency.city_name} ${this.agency.zipcode}`
      : "Adresse"
  }
  async createAgency() {
    this.loading = true
    if (this.placeId != "") await this.fetchAgencyAdressDetails()
    await this.agency.save()
    if (this.onValidate) this.onValidate(this.agency)
    else {
      snackbar({
        color: "success",
        content: `L'agence ${this.agency.name} a correctement été ${
          this.agency.id ? "mise à jour" : "créée"
        } 👌`,
      })
      this.$emit("created", this.agency)
    }
    this.loading = false
    this.dialog.close()
  }
  async uploadAgencyLogo(event: Event) {
    const file = (event.target as any).files[0]
    if (file) {
      const fileReader = new FileReader()
      fileReader.onloadend = ({ target }) =>
        (this.agency.logo = String(target?.result))
      fileReader.readAsDataURL(file)
    } else this.agency.logo = null
  }

  get landlineNumberValidation() {
    return [
      this.notEmpty,
      this.phoneNumber,
      this.isPhoneNumberAvailable ? true : "Numéro non disponible",
    ]
  }

  @Watch("searchBrands")
  async findBrands(value = "") {
    const response = await brands.search(value)
    this.brands = response.hits as Hit<Brand>[]
  }
  @Watch("searchAddress")
  async findAdresses(value: string) {
    if (!value) return
    const results = await autocompletePlace(value)
    if (results) this.predictedPlaces = results
  }
  @Watch("brands")
  watchBrand() {
    this.setCategoryFromBrand()
  }

  @Watch("agency.landline_number")
  async isPhoneNumberAvailableOnAlgolia(value: string | null) {
    if (!value) {
      this.isPhoneNumberAvailable = true
    }
    const formattedPhoneNumber = this.$options.filters?.formatPhoneNumber(value)
    if (isValidPhoneNumber(formattedPhoneNumber)) {
      const response = await agencies.search(formattedPhoneNumber)
      this.isPhoneNumberAvailable =
        response.hits.length == 0 ||
        (response.hits[0] as Hit<Agency>)["id"] == this.agency.id
    }
  }

  setCategoryFromBrand() {
    if (this.agency.brand_id == null) return
    let brandCategory = this.brands?.find(
      (brand) => brand.id == this.agency.brand_id
    )?.category
    if (brandCategory) this.agency.category = brandCategory
  }
  async fetchAgencyAdressDetails() {
    const details = await geocodePlaceId(this.placeId)
    this.agency.street_address = details.join(["street_number", "route"])
    this.agency.city_name = details.find("locality")
    this.agency.zipcode = details.find("postal_code")
  }

  customFilter(item: any) {
    return item
  }

  // rules
  notEmptyAddress = (value: string | Array<any> | null) =>
    !!(this.agency && this.agency.street_address) ||
    !!(value && value.length) ||
    "Champ requis"
  notEmpty = (value: string | Array<any> | null) =>
    !!(value && value.length) || "Champ requis"
  phoneNumber = (value: string) =>
    !value || isValidPhoneNumber(value) || "Numéro incorrect"
}
